import type {
    ConfigurationMarker as ConfigurationMarkerType,
    PositionData,
    Addon,
    Zone,
    ActiveInterchangeable,
} from '@/types/configurationmarkers.types';
import Price from '@/etc/price';
import { Marker } from '@/utils/classes/Marker';
import { computed } from 'vue';
import { ProductGroup } from '@/types/productgroups.types';
import { useMarkerStore, useGeneralStore } from '@/stores';
import * as _ from 'lodash-es';
import { ProductVariant } from './ProductVariant';
import * as zoneActions from '@/utils/Zone';
import * as visualizerAction from "@/utils/visualizer";
import { CreateNotification } from '@/types/notifications.types';
import i18n from '@/lang/i18n';

export class ConfigurationMarker implements ConfigurationMarkerType {

    xid: number;
    id: string;
    configuration_id: number;
    position_data: PositionData;
    marker_id: number;
    productVariants: ProductVariant[];
    addons: Addon[];
    zones: Zone[];
    interchangeables: ActiveInterchangeable[];

    constructor (configurationMarkerData) {
        const currentXids = useMarkerStore().configurationMarkers.map(marker => marker.xid);

        this.xid = `${currentXids.length ? Math.max(...currentXids) + 1 : 1}`;
        this.id = configurationMarkerData['id'] ?? null;
        this.configuration_id = configurationMarkerData['configuration_id'] ?? null;
        this.position_data = configurationMarkerData['position_data'] ?? null;
        this.marker_id = configurationMarkerData['marker_id'] ?? null;
        this.productVariants = configurationMarkerData['productVariants'] ?? [];
        this.addons = configurationMarkerData['addons'] ?? [];
        this.zones = configurationMarkerData['zones'] ?? [];
        this.interchangeables = configurationMarkerData['interchangeables'] ?? [];
    }

    get marker (): Marker {
        return computed (() => {
            return useMarkerStore().markers.find((marker) => {
                return marker.id == this.marker_id;
            });
        }) as any;
    }

    get productGroups (): ProductGroup[] {
        return this.marker.productGroups;
    }

    get selectedProductGroups (): ProductGroup[] {
        const selectedProductGroupIds = this.productVariants.map(variant => variant.product_group_id);

        return this.productGroups.filter((productGroup) => {
            return selectedProductGroupIds.includes(productGroup.id);
        });
    }

    get primaryProductGroup (): ProductGroup {
        const markerStore = useMarkerStore();

		return computed(() => {
			// Check if there is an active interchangeable for this marker 
			const activeInterchangeable = markerStore.getActiveInterchangeable(this.xid);
			
			if (activeInterchangeable && activeInterchangeable.subject_type === 'product_group') {
				return activeInterchangeable.subject
			}

			return this.marker.getFirstChildRecursive(this.marker.id, 'marker').subject;
		}) as any;
        // return this.marker.primaryProductGroup;
    }

	originalVariant(productGroup) {
		return this.productVariants.find(productVariant => {
			return productVariant.product_group_id == productGroup.id;
		});
	}

    get primaryProductVariant (): ProductVariant | undefined {
        return computed (() => {
            return this.productVariants.find((variant) => {
                return variant.product_group_id == this.primaryProductGroup.id;
            });
        }) as any;
    }

    get price (): Price {
        return computed(() => {
            return new Price(
                this.totalCost,
                useGeneralStore().currency,
            );
        }) as any;
    }

    get totalCost (): number {
        return computed(() => {
            if (!this.marker.sellable) {
                return 0;
            }

            return this.productVariants.reduce((accumulator, variant) => {
                return accumulator + variant.calculatedPrice.price;
            }, 0);
        }) as any;
    }

    managesZones() {
        return this.marker.productGroups.some(group => !!group.wall_covering);
    }

    getZoneProductGroup() {
        return this.marker.productGroups.find((group) => !!group.wall_covering);
    }

    getVariantProductGroup(variant: ProductVariant) {
        return this.productGroups.find((group) => group.id == variant.product_group_id);
    }

    getAdjustableProductVariant() {
        return this.productVariants.find((variant) => !!variant.adjustable);
    }

    getChangedPrices () {

        if (!this.marker.sellable) {
            return [];
        }

        const changes = [];

        this.marker.productGroups.forEach((productGroup) => {
            const variant = this.originalVariant(productGroup);

            if (!variant) {
                return;
            }

            if (!variant.priceHasChanged()) {
                return;
            }

            changes.push({
                variant,
                productGroup,
                new_price: variant.calculatedPrice,
                old_price: variant.originalCalculatedPrice,
            });
        });

        return changes;
    }

    removeVariant(variant:ProductVariant){
        visualizerAction.deleteObjectModular(this.xid, variant);
    }

    selectVariant(variant: ProductVariant): CreateNotification[]  {
        const currentVariantIndex = _.findIndex(this.productVariants, {product_group_id: variant.product_group_id});
        const currentVariant = this.productVariants[currentVariantIndex];

        variant.setSimilarPositioningRule(this, currentVariant?.selectedPositioningRule, currentVariant?.custom_offsets);

        variant.updateQuantity(this);

        if (currentVariantIndex > -1) {
            this.productVariants[currentVariantIndex] = variant;
        } else {
            this.productVariants.push(variant);
        }

        return this.visualizeVariant(variant);
    }

    clearProductGroup(productGroup: ProductGroup) {
        this.productVariants.splice(_.findIndex(this.productVariants, {product_group_id: productGroup.id}), 1);
    }

    visualizeVariant(variant: ProductVariant): CreateNotification[] {
        const productGroup = this.getVariantProductGroup(variant);
        const notifications: CreateNotification[] = [];

        if (productGroup.wall_covering){
            this.triggerWallCovering(variant, productGroup);
            return notifications;
        }

        const sendResult = visualizerAction.send(this, variant, productGroup === this.primaryProductGroup);

        // Stop when 'basemodel' is already send to the visualizer
        if(!sendResult){
            return notifications;
        }

        const result = visualizerAction.sendToVisualizer(this, variant);

        if (result.error) {
            notifications.push({
                type:'warning',
                title: result.message,
                message: i18n.global.t('no-visualization-possible', {subject: variant.product_name}),
                duration: 20,
            });
        }

        return notifications;
    }

    triggerWallCovering(variant: ProductVariant, productGroup: ProductGroup) {
        //Remove old zones
        zoneActions.removeZones(this, true);

        zoneActions.createZonesAndSendToVisualizer(
            this,
            variant,
            productGroup,
        );
    }

    changeRotation(rotation: number) {
        this.position_data.rotation = rotation;
    }

    changePositionData(data) {
        this.position_data.x = data.x;
        this.position_data.y = data.y;
        this.position_data.wall = data.wall;
    }
}
