import ObjectRoom from "@/events/ObjectRoom";
import ObjectModel from "@/events/ObjectModel";
import ObjectModular from "@/events/ObjectModularUpdate";
import BaseObject from "@/events/Object"
import WallObject from "@/events/ObjectWall";
import WallZoneObject from "@/events/ObjectWallZone";
import { ProductVariant } from '@/utils/classes/ProductVariant';
import type { ProductGroup } from "@/types/productgroups.types";
import type { Coordinates } from "@/types/coordinates.types";
import { DrawingType } from '@/types/drawinglogic.types';
import type { CreateNotification } from "@/types/notifications.types";
import { ConfigurationMarker } from "./classes/ConfigurationMarker";
import * as _ from 'lodash-es';
import i18n from '../lang/i18n.js';

export function send(configurationMarker: ConfigurationMarker, variant: ProductVariant, isPrimaryProductGroupActive: boolean) {

    let drawing_logic = configurationMarker.marker.drawing_logic;

    if (drawing_logic === DrawingType.FLOOR || drawing_logic === DrawingType.WALL) {
        if(!variant){
            return;
        }

        updateObjectRoom(variant, configurationMarker);
        return;
    }

    if (!isPrimaryProductGroupActive) {
        return true;
    }

    //Update or set Object
    updateObjectModel(configurationMarker, variant);

    //move over old positioningrules
    updatePositioningRules(configurationMarker);
    return;
}

export function updateObjectRoom(variant: ProductVariant, configurationMarker: ConfigurationMarker) {
    new ObjectRoom({
        variant: variant,
        wall_id: configurationMarker.position_data.wall
    }).materialUpdate();
}

export function updateObjectModel(configurationMarker: ConfigurationMarker, variant: ProductVariant) {
    if(!variant || !variant.model_reference){
        return;
    }
    //Check wether an offset height is active on the productGroup or variant
    variant.height_offset = getOffsetHeight(configurationMarker.productGroups, variant);

    // const newObjectModel = new ObjectModel({
    //     object_id: configurationMarker.xid,
    //     variant: variant,
    //     type: configurationMarker.marker.name,
    //     position_data: configurationMarker.position_data,
    //     encasing: configurationMarker.marker.encasing,
    // });
    const newObjectModel = new ObjectModel(configurationMarker, variant);
    newObjectModel.set();

    variant.complementaryModels?.forEach(complementaryModel => {
        const complementaryObject = new ObjectModular({
            object_id: configurationMarker.xid,
            variant: {
                model_reference: complementaryModel.reference,
                product_group_id: parseInt(variant.product_group_id + '' +  complementaryModel.type_id),
            },
            positioning_name: (variant.product_name ?? '') + '_' + complementaryModel.reference,
            positioning_rule: complementaryModel.positioningRules[0],
        });

        complementaryObject.update();
    });
}

export function updatePositioningRules(configurationMarker: ConfigurationMarker) {
    if (configurationMarker.productVariants && Object.keys(configurationMarker.productVariants).length > 0) {
        configurationMarker.productVariants.forEach(productVariant => {
            if (productVariant.positioning_rules && productVariant.positioning_rules.length) {
                sendToVisualizer(configurationMarker, productVariant);
            }
        });
    }
}

export function sendToVisualizer(configurationMarker: ConfigurationMarker, variant: ProductVariant) {
    if (!variant.positioning_rule_id) {
        return { error: true, message: i18n.global.t('no-positioning-found'), variant: variant };
    }

    const newObjectModular = new ObjectModular({
        object_id: configurationMarker.xid,
        variant: variant,
        positioning_name: variant.product_name ?? '',
        positioning_rule: variant.selectedPositioningRule
    });
    newObjectModular.update();

    variant.complementaryModels?.forEach(complementaryModel => {
        const complementaryObject = new ObjectModular({
            object_id: configurationMarker.xid,
            variant: {
                model_reference: complementaryModel.reference,
                product_group_id: parseInt(variant.product_group_id + '' +  complementaryModel.type_id),
            },
            positioning_name: (variant.product_name ?? '') + '_' + complementaryModel.reference,
            positioning_rule: complementaryModel.positioningRules[0],
        });

        complementaryObject.update();
    });

    return { error: false,  message: '' };
}

export function getOffsetHeight(productGroups: ProductGroup[], variant: ProductVariant){
    let heightOffset = productGroups.find(group => group.id === variant.product_group_id)?.height_offset;

    if(variant.height_offset > 0){
        heightOffset = variant.height_offset;
    }

    return heightOffset;
}

export function deleteObjectModular(xid: string, variant: ProductVariant){
    const object = new ObjectModular({
        object_id: xid,
        variant: variant,
    });

    object.delete();
}


/**
 * Create a Base Object in the visualizer
 */
export function createBaseObject(data){
    return new BaseObject({
        object_id: data.xid,
        point: data.points,
        drawing_logic: data.drawing_logic,
        carve: data.carve,
        marker_positioning: data.marker_positioning
    })
}

/**
 * Delete a Base Object in the visualizer
 */
export function deleteBaseObject(xid: number){
    const viualizerObject = createBaseObject({xid: xid})
    viualizerObject.delete();
}

/**
 * Move a visualizer object
 */
export function moveObject(xid: number, visualizerPosition: {x: number; y: number; rotation: number}){
    new BaseObject({
        object_id: xid,
        point: visualizerPosition,
    }).move();
}

/**
 * Create a Wall in the visualizer
 */
export function createWallObject(data){
    return new WallObject({
        id: data.wall_id,
        height: data.height,
        from: data.from,
        to: data.to
    })
}


/**
 * Delete a Wall in the visualizer
 */
export function deleteWallObject(wallId: number){
    const wallObject = createWallObject({wall_id: wallId})
    wallObject.delete();
}

/**
 * Move a wall object
 */
export function moveWallObject(wallId: number, position: {from: Coordinates; to: Coordinates;}){
    const wallObject = createWallObject({
            wall_id: wallId,
            from: position.from,
            to: position.to,
        });

    wallObject.update();
}

export function createZoneObject(data, xid: number, variant?: ProductVariant){
    return new WallZoneObject({
        id: xid + '-' + data.id,
        wall_id: data.wall_id,
        side: data.side,
        variant: variant,
        from: data.from,
        to: data.to
    })
}

// Send all non primary products to the visualizer
export function handleNonPrimaryProductVariant(
    newConfigurationMarker: ConfigurationMarker,
    primaryProductVariantId: number,
): CreateNotification[] {
    const notifications: CreateNotification[] = [];

    newConfigurationMarker.productVariants.forEach((productVariant) => {
        if (productVariant.id !== primaryProductVariantId) {
            const positioningRule = _.orderBy(productVariant.positioning_rules, ['priority']).find(rule => rule != undefined);

            productVariant.positioning_rule_id = positioningRule?.id;
            productVariant.updateQuantity(newConfigurationMarker);

            const result = sendToVisualizer(newConfigurationMarker, productVariant);

            if (result.error) {
                const productGroup = newConfigurationMarker.productGroups.find(group => group.id === productVariant.product_group_id);

                notifications.push({
                    type:'warning',
                    title: result.message,
                    message: i18n.global.t('no-visualization-possible', {subject: productGroup.name}),
                    duration: 20,
                })
            }
        }
    })

    return notifications;
}
