import { Configuration } from "@/types/configuration.types";
import { defineStore } from "pinia";
import axios from 'axios';
import { useGeneralStore } from "./general";
import { useMarkerStore } from "./marker";
import { useRoomStore } from "./rooms";
import { ref, watch, computed } from 'vue';
import hash from 'object-hash';
import * as _ from 'lodash-es';

export const useConfigurationStore = defineStore('configuration', () => {

    const markerStore = useMarkerStore();
    const generalStore = useGeneralStore();
    const roomStore = useRoomStore();

    const configuration = ref(null);
    const savingConfiguration = ref(false);
    const currentConfigurationHash = ref(null);
    const lastStoredConfigurationHash = ref(null);

    let firstConfigurationHash = true;

    const updateCurrentConfigurationHash = _.debounce(function () {
        currentConfigurationHash.value = hash({
            markers: markerStore.configurationMarkers,
            room: roomStore.room,
            vat: generalStore.active_vat_setting,
            interchangeables: markerStore.activeInterchangeables, // Hash includes interchangeables
        });

        if (firstConfigurationHash) {
            lastStoredConfigurationHash.value = currentConfigurationHash.value;
            firstConfigurationHash = false;
        }
    }, 500);

    watch(() => [
        markerStore.configurationMarkers, 
        roomStore.room, 
        generalStore.active_vat_setting,
        markerStore.activeInterchangeables, // Add activeInterchangeables
    ], () => {
        updateCurrentConfigurationHash({
            markers: markerStore.configurationMarkers,
            room: roomStore.room,
            vat: generalStore.active_vat_setting,
            interchangeables: markerStore.activeInterchangeables,
        });
    }, {
        deep: true
    });

    const isDirty = computed(() => {
        return currentConfigurationHash.value != lastStoredConfigurationHash.value;
    });

    const configurationExists = computed(() => {
        return !!configuration.value.owner;
    });

     function $reset () {
        configuration.value = null;
    }

    async function setConfiguration(newConfiguration: Configuration) {
        // configuration.value = newConfiguration;
        configuration.value = {
            ...newConfiguration,
            interchangeables: markerStore.activeInterchangeables,
        };
        firstConfigurationHash = true;
    }

    async function  saveConfiguration (storeAsNew = false, additionalData = {}) {

        savingConfiguration.value = true;

        try {

            const endpoint = storeAsNew
                ? route('client.configurations.store')
                : route('client.configurations.patch', {
                    configuration: configuration.value.id,
                });

            const { floorplan_data, room_height, square_meters } = useRoomStore().room
            const response = await axios[storeAsNew ? 'post' : 'patch'](endpoint, {
                room: { floorplan_data, room_height, square_meters },
                room_type: 'bathroom',
                vat: generalStore.vatAmount.toString(),
                configurationMarkers: markerStore.configurationMarkers,
                interchangeables: configuration.value?.interchangeables ?? [], // Include interchangeables
                ...additionalData,
            });

            savingConfiguration.value = false;

            if (!storeAsNew) {
                lastStoredConfigurationHash.value = currentConfigurationHash.value;
            }

            return response.data;
        } catch (error) {
            this.createNotification({
                type: 'error',
                message: error.message,
            });

            savingConfiguration.value = false;

            throw error;
        }
    }

    return {
        configuration,
        savingConfiguration,
        currentConfigurationHash,
        lastStoredConfigurationHash,
        isDirty,
        configurationExists,
        setConfiguration,
        saveConfiguration,
        $reset,
    }
});
